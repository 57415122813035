import Head from "next/head";
import Header from "./header/headerRegistration";

export default function LayoutRegistration({ children, modalClick }) {
  return (
    <>
      <Head>
      <meta name="facebook-domain-verification" content="yrkhmpfyyd9jftxkgxtqmm799n5x6w" />
      </Head>
      <Header />
      <main>{children}</main>
    </>
  );
}

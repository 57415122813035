import Link from "next/link";
import SanityImage from "./sanityImage";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { normalizeRepeatedSlashes } from "next/dist/shared/lib/utils";
import { slidesOnLeft } from "react-slick/lib/utils/innerSliderUtils";

const myPortableTextComponents = {
  types: {
    image: ({ value }) => {
      return (
        <div className="inline_image">
          <SanityImage image={value.asset} />
        </div>
      );
    },
    embed: ({ value }) => {

      function createEmbed() {
        return {__html: value.embed_code};
      }

      return (
        <div className="embed_code" dangerouslySetInnerHTML={createEmbed()}>

        </div>
      );
    },
    youtube: ({ value }) => {
      const { url } = value;
      const id = getYouTubeId(url);
      return (
        <div className="inline_video">
          <YouTube videoId={id} containerClassName={"youtubeContainer"} />
        </div>
      );
    },
  },
  marks: {
    link: ({ children, value }) => {
      let target = "_blank";
      let rel = "noreferrer noopener";
      let linkClass = "";
      if (!value.blank) {
          target = "_parent";
          rel = null;
      }
      if (value.button) {
        linkClass = "btn";
      }
      return (
        <a href={value.href} rel={rel} target={target} className={linkClass}>
          {children}
        </a>
      );
    },
    internalLink: ({ children, value }) => {
      const { slug = {} } = value;
      const href = `/${slug.current}`;
      return (
        <Link href={href}>
          <a>{children}</a>
        </Link>
      );
    },
    color: ({ children, value }) => {
      const { hex } = value;
      return <span style={{ color: hex }}>{children}</span>;
    },
  },
};

export default myPortableTextComponents;

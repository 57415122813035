import styles from "./header.module.scss";
import Image from "next/image";
import headerLogo from "../../public/images/looptv_logo_nyse.png";
import Link from "../utilities/link";
import Button from "../../components/button/button";

export default function Header() {
  let headerClass = `${styles.header}`;

  return (
    <header className={headerClass}>
      <div className={styles.logo}>
        <Link href="/">
          <a>
            <Image src={headerLogo} alt="Loop" />
          </a>
        </Link>
      </div>
      <div className={styles.homeBtn}>
        <Link href="/">
          <a>
            <Button label="TAKE ME HOME" />
          </a>
        </Link>
      </div>
    </header>
  );
}

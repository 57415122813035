import styles from "./header.module.scss";
import Image from "next/image";
import headerLogo from "../../public/images/looptv_logo_nyse.png";
import Link from "../utilities/link";
import Button from "../../components/button/button";
import { useRouter } from "next/router";
// import Modal from "../../components/modals/freePlayer.js";
import { useState } from "react";

export default function Header({ modalClick }) {
  const router = useRouter();
  const [menuOn, setMenuOn] = useState(false);

  let headerClass = `${styles.header}`;
  if (menuOn) {
    headerClass += ` ${styles.menuOn}`;
  }

  function closeMenu() {
    setMenuOn(false);
  }

  return (
    <header className={headerClass}>
      <div className={styles.logo}>
        <Link href="/">
          <a>
            <Image src={headerLogo} alt="Loop" />
          </a>
        </Link>
      </div>
      <nav className={styles.nav} onClick={closeMenu}>
        <ul>
          <li>
            <Link href="/business">
              <a
                className={router.asPath === "/business" ? styles.pageOn : styles.link}
              >
                businesses
              </a>
            </Link>
          </li>
          <li>
            <Link href="/advertisers">
              <a className={router.asPath === "/advertisers" ? styles.pageOn : styles.link}>advertisers</a>
            </Link>
          </li>
          <li>
            <Link href="/investors">
              <a className={router.asPath === "/investors" ? styles.pageOn : styles.link}>investors</a>
            </Link>
          </li>
          <li>
            <Link href="/channels">
              <a className={router.asPath === "/channels" ? styles.pageOn : styles.link}>channels</a>
            </Link>
          </li>
          {/* <li onClick={() => Toggle()}>
            <Button label="get a free player" />
          </li> */}
          <li className={styles.button_wrap}>
            <Link href="/registration/step1">
              <a>
                <Button label="get your free player" />
              </a>
            </Link>            
          </li>
          <li>
            <Link href="https://biz.loop.tv/registration/?signin=true">
              <a target="_blank" className={router.asPath === "/sign-in" ? styles.pageOn : styles.link}>sign in</a>
            </Link>
          </li>
        </ul>
      </nav>
      <div className={styles.menuBtn} onClick={() => setMenuOn(!menuOn)}>
        <div className={styles.wrap}>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </div>
      </div>
    </header>
  );
}

import Header from "./header/header";
import Footer from "./footer/footer";
import Head from "next/head";

export default function Layout({ children, modalClick }) {
  return (
    <>
      <Head>
      <meta name="facebook-domain-verification" content="yrkhmpfyyd9jftxkgxtqmm799n5x6w" />
      </Head>
      <Header modalClick={modalClick} />
      <main>{children}</main>
      <Footer />
    </>
  );
}

import client from '../../client';
import Image from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import loopLogo from '../../public/images/Loop-Logo-White.jpeg';

export default function SanityImage({image, altText, layout, objectPosition = "center"}) {

    const imageProps = useNextSanityImage(
        client,
        image
    );

    if (!imageProps) {
        return (
            <Image src={loopLogo} alt="Loop" layout="fill" />
        )
    }

    if (layout === "fill") {
        return (
            <Image src={imageProps.src} layout="fill" objectFit="cover" objectPosition={objectPosition} alt={altText} />
        )
    } else if (layout === "contain") {
        return (
            <Image src={imageProps.src} layout="fill" objectFit="contain" alt={altText} />
        )
    } else {
        return (
            <Image {...imageProps} alt={altText} />
        )
    }

}
import styles from "./mainModal.module.scss";
import Image from "next/image";
import { useState, useRef, useEffect } from "react";
import Button from "../../components/button/button";
import loopLogo from "../../public/images/looptv_logo.png";
import Link from "next/link";
import { useRouter } from "next/router";

const MainModal = ({ show, close }) => {
  const router = useRouter();
  const closeModal = () => {
    close();
  };
  const closeModalBusiness = () => {
    close();
    router.push("/");
  };
  const closeModalAdvertiser = () => {
    close();
    router.push("/advertisers");
  };
  const closeModalArtist = () => {
    close();
    router.push("/artists");
  };
  useEffect(() => {
    show && (document.body.style.overflow = "hidden");
    !show && (document.body.style.overflow = "unset");
  }, [show]);

  return (
    <>
      {show ? (
        <div className={styles.modalContainer}>
          <div className={styles.modal}>
            <div className={styles.header}>
              <h1>Before we loop you in, tell us who you are:</h1>
            </div>
            <div className={styles.buttonWrapper}>
              <div className={styles.button} onClick={closeModalBusiness}>
                  <Button
                    label="I'm a business owner"
                    size="medium"
                    width="230px"
                  />
              </div>
              <div className={styles.button} onClick={closeModalAdvertiser}>
                <Button label="I'm an advertiser" size="medium" width="230px" />
              </div>
              <div className={styles.button} onClick={closeModalArtist}>
                <Button label="I'm an artist" size="medium" width="230px" />
              </div>
            </div>
            <div className={styles.pButton} onClick={closeModal}>
              <p>I’m just a normal human who loves music videos</p>
            </div>
            <div className={styles.logo}>
              <Image src={loopLogo} alt="Background Image" />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MainModal;

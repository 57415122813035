import "../styles/globals.scss";
import Layout from "../components/layout";
import LayoutRegistration from "../components/layoutRegistration";
import Modal from "../components/modals/freePlayer.js";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import MainModal from "../components/modals/mainModal.js";
import TagManager from "react-gtm-module";
import {
  FBPixelScript,
  FBPixelProvider,
} from "@rivercode/facebook-conversion-api-nextjs/components";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }) {
  // set cookies for player registration and marketing campaigns
  var getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };
  var setCookie = function (cname, cvalue, exdays) {
    //Cookies.set(cname, cvalue, { expires: exdays });
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    //document.cookie = cname + "=" + cvalue + ";" + expires + ";" + "domain=.loop.tv;path=/";
    document.cookie = cname + "=" + cvalue + ";" + expires; // removed domain requirement from cookie for preview URLs
  };
  var getUrlParameter = function (name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const [modal, setModal] = useState(false);
  const [affVerified, setAffVerified] = useState(false);
  const ModalToggle = () => {
    if (modal) {
      document.querySelector("body").classList.remove("overlay_on");
    } else {
      let affparam1 = getCookie("affparam1");

      if (affparam1 === "verified") {
        setAffVerified(true);
      }
      document.querySelector("body").classList.add("overlay_on");
    }
    setModal(!modal);
  };
  const [mainModal, setMainModal] = useState(false);
  const MainModalToggle = () => setMainModal(!mainModal);

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-WFNR7WH" });

    window.pypestreamConfig = {
      APP_ID: "2ff4e74f-7a95-4d12-83a5-fb96ee134969",
      screen_name: "landing",
    };

    var pypeScript = document.createElement("script");
    pypeScript.type = "text/javascript";
    pypeScript.src = "https://web.pypestream.com/launcher.js?ts=" + Date.now();
    pypeScript.async = true;
    document.getElementsByTagName("head")[0].appendChild(pypeScript);

    let hasSeenGate = Cookies.get("sawGate");
    if (hasSeenGate !== "yes") {
      // show modal - TURNED OFF FOR A/B TESTING ON 5/12/22
      setMainModal(false);
      // set cookie
      setGateCookie(30);
    }

    var utmTerm = getUrlParameter("utm_term");
    var utmContent = getUrlParameter("utm_content");
    var utmSource = getUrlParameter("utm_source");
    var affParam1 = getUrlParameter("aff_param1");

    // clear out any existing campaign and affiliate cookies
    setCookie("campaign", "", -10);
    setCookie("affiliate", "", -10);

    if (affParam1 != "") {
      setCookie("affparam1", affParam1);
    } else {
      // clear affparam1 cookie
      setCookie("affparam1", "");
    }

    if (utmTerm) {
      if (utmContent) {
        utmTerm += " / " + utmContent;
      }
      if (utmSource) {
        utmTerm += " / " + utmSource;
      }
      setCookie("campaign", "utm -> " + utmTerm, 365);
    } else if (document.referrer && document.referrer.indexOf("http") === 0) {
      var campaign = getCookie("campaign");
      if (campaign && campaign.indexOf("organic") !== 0) {
        var ref = new URL(document.referrer);
        var host = ref.hostname.replace("www.", "");
        if (host !== "loop.tv") {
          setCookie("campaign", "organic -> " + host, 365);
        }
      }
    }
    /*
  //var promoCode = window.location.pathname.split("/").filter(function(e){return e}).pop();
  var promoCode = getUrlParameter("campaign");
  if (promoCode && promoCode.length > 0 && promoCode !== "business") {
      setCookie("campaign", promoCode, 365);
  }
  else {
      var affiliateRef = getUrlParameter("click_id");
      if (affiliateRef) {
          setCookie("campaign", "affiliate_" + affiliateRef, 365);
      }
  }
  */

    var promoCode = getUrlParameter("campaign");
    if (promoCode && promoCode.length > 0 && promoCode !== "business") {
      setCookie("campaign", promoCode, 365);
    }

    // moving this as last check on the page
    var affiliateRef = getUrlParameter("click_id");
    if (affiliateRef) {
      setCookie("affiliate", "affiliate_" + affiliateRef, 365);
    }
  }, [mainModal]);

  function setGateCookie(expDays) {
    Cookies.set("sawGate", "yes", { expires: expDays });
  }

  let playerQtyStart = 0;
  if (affVerified) {
    playerQtyStart = "";
  }

  const router = useRouter();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    business: "",
    address: "",
    address_2: "",
    phone: "",
    website: "",
    business_type: "414b44d0-6c74-11eb-ad96-c3bbdeacf1a4", // default to family entertainment when field is hidden
    number_venues: "1",
    number_tvs: "1",
    player_qty: playerQtyStart,
    password: "",
  });
  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };
  if (router.pathname.startsWith("/registration/")) {
    return (
      <>
        <FBPixelScript />
        <FBPixelProvider>
          <LayoutRegistration modalClick={ModalToggle}>
            <Component
              {...pageProps}
              modalClick={ModalToggle}
              updateFormData={updateFormData}
              formData={formData}
            />
            <Modal show={modal} close={ModalToggle} affVerified={affVerified} />
            <MainModal show={mainModal} close={MainModalToggle} />
          </LayoutRegistration>
        </FBPixelProvider>
      </>
    );
  }

  return (
    <>
      <FBPixelScript />
      <FBPixelProvider>
        <Layout modalClick={ModalToggle}>
          <Component {...pageProps} modalClick={ModalToggle} />
          <Modal show={modal} close={ModalToggle} affVerified={affVerified} />
          <MainModal show={mainModal} close={MainModalToggle} />
        </Layout>
      </FBPixelProvider>
    </>
  );
}

export default MyApp;
